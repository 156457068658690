<script setup lang="ts">
import { useMounted } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useEventListener } from '@vueuse/core';
// ---------------------------------------
const isMounted = useMounted();
const error = useError();
const router = useRouter();
// ---------------------------------------

const code = computed(() => {
  if (error instanceof Error) return error.message;
  if (!('value' in (error as any))) return '';
  if (!(error as any).value) return '';
  if (!('statusCode' in (error as any).value)) return '';
  return (error as any).value.statusCode;
});

const message = computed(() => {
  // console.log('e', error, error instanceof Error);
  if (error instanceof Error) return error.message;
  if (!('value' in (error as any))) return '';
  if (!(error as any).value) return '';
  if (!('message' in (error as any).value)) return '';
  return error.value?.message;
});

const height = ref(0);

const style = computed(() => {
  if (!isMounted.value) return { height: `100vh` };
  if (height.value === 0) return { height: `100vh` };
  return { height: `${height.value}px` };
});
onMounted(() => {
  height.value = window.innerHeight;
  const cleanup = useEventListener(window, 'resize', () => {
    // console.log(window.innerHeight);
    height.value = window.innerHeight;
  });
  onUnmounted(() => {
    cleanup();
  });
});

// ---------------------------------------
</script>
<template>
  <section :style="style">
    <div class="error-container">
      <div v-if="code" class="title f-rubik">{{ code }}</div>
      <div v-if="code == '404'" class="title f-rubik">Not Found</div>
      <div v-else class="title f-rubik">Error</div>
      <div class="message">{{ message }}</div>
      <v-btn
        variant="flat"
        size="x-large"
        color="light-blue-darken-3"
        class="f-rubik border border-white"
        @click="router.push({ path: '/' })"
      >
        <v-icon icon="mdi-arrow-left"></v-icon>Go Home
      </v-btn>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.message {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
.color-test {
  > div {
    padding: 10px;
  }
  > div > * {
    margin: 5px 0;
  }
}
section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-container {
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  padding: 20px 40px;
  border: solid 3px white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 16px;
  width: 380px;
  line-height: 2.4em;
}
.title {
  font-size: 40px;
}

@media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
  .error-container {
    width: calc(100% - 30px);
    line-height: 1.6em;
    padding: 10px 20px;
  }

  .title {
    font-size: 20px;
  }
  button {
    margin-top: 10px;
  }
}

@media screen and (min-width: #{ 400 }px) and (max-width: #{ 600 - 0.1}px) {
  .error-container {
    width: 380px;
  }

  .title {
    font-size: 36px;
  }
}

@media screen and (min-width: #{ 600 }px) {
  .error-container {
    width: 580px;
  }

  .title {
    font-size: 40px;
  }
}
</style>
